import type { IHomeBanner } from '@sticky/sticky-common/src/resources/types/banner/homeBanner';

const translations: IHomeBanner[] = [
  {
    title: 'Confirmez vos voyages !',
    mainMessage:
      'Pensez à confirmer vos voyages à partir de 48 heures avant le départ et au plus tard la veille à 17h.',
    secondMessage:
      'Sans confirmation de votre part, votre réservation sera annulée.',
  },
];

export default translations;
