import { getFeatures } from '@sticky/config';
import { i18n } from '@sticky/i18n';
import type { IHomeBanner } from '@sticky/sticky-common/src/resources/types/banner/homeBanner';

import bannerHome from './resources/translations/locales/fr/banner-home';
import {
  auth,
  cgv,
  contactFaq,
  disclaimer,
  errorCode,
  errors,
  freePlaces,
  menu,
  proposal,
  reservation,
  subscription,
  widgetMyId,
} from './resources/translations/locales/fr/common';
import { home } from './resources/translations/locales/fr/pages';
import {
  stepperDocumentsCheck,
  stepperSubscription,
} from './resources/translations/locales/fr/steppers';

const resources = {
  cgv,
  disclaimer,
  home,
  subscription,
  menu,
  contactFaq,
  proposal,
  auth,
  reservation,
  stepperSubscription,
  stepperDocumentsCheck,
  errors,
  widgetMyId,
  errorCode,
  'free-places': freePlaces,
};

i18n.addResourceBundle('fr', 'app', resources, true, true);

// Activate banner text only if feature is enabled
if (getFeatures().banners.activeOnHomePage) {
  i18n.addResourceBundle(
    'fr',
    'app',
    {
      bannerHome,
    },
    true,
    true,
  );
}

type TmsKeys = typeof resources & {
  bannerHome: IHomeBanner[];
};

declare module '@sticky/i18n' {
  export interface KeysOverrides extends TmsKeys {}
}

export default i18n;
