import { lazy } from 'react';

import { t } from '@sticky/i18n';
import { LAYOUT } from '@sticky/sticky-common/src/routes/layout';
import type { IRoute } from '@sticky/sticky-common/src/routes/routes';
import { Routes } from '@sticky/sticky-common/src/routes/routes';

import { Home } from './pages/home/home';

// Define routes
export const routes: IRoute[] = [
  {
    element: Home,
    id: 'home',
    path: '/',
    title: t('home.pageTitle'),
    layout: LAYOUT.HOME,
  },
  {
    element: lazy(() => import('./pages/customer/customer-travels')),
    id: 'customer/my-travels',
    path: '/mes-voyages',
    title: t('customer.travels.index'),
    layout: LAYOUT.CUSTOMER,
    private: true,
  },
];

// Use new class
Routes.addRoutes(routes);
